.offering-collections-highlight {
    margin-top: $gap-xlarge;

    .offering-collections-highlight__heading {
        @include font-primary-regular();
        font-size: 40px;
        line-height: 48px;
        color: $primary;
        margin: 0;
        text-transform: uppercase;

        strong {
            @include font-primary-bold();
        }
    }

    .offering-collections-highlight__description {
        margin: 17px 0 31px;

        p {
            font-size: 22px;
            line-height: 26px;
        }
    }

    .offering-collections-highlight__links {
        // Change to use custom width for bootstrap classes because of added margins between the gap of elements.
        // To make it fit the necessary elements on the same row.
        @include media-breakpoint-only(md) {
            .col-md-6 {
                width: 47%;
            }
        }

        @include media-breakpoint-up(lg) {
            .col-lg-4 {
                width: 31%;
            }
        }
    }

    .offering-collections-highlight__link {
        @include font-primary-bold();
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $gap-medium;
        text-transform: uppercase;
        color: $primary;
        // Using 130px as min-height instead of 120px in figma because of second row content going over 120px.
        // Would need to figure out how to make all the boxes equal height in terms of content.
        min-height: 130px;
        background-color: #f5f5f5;
        margin-right: $gap-large;
        margin-bottom: $gap-large;
        overflow: hidden;
    }

    .offering-collections-highlight__link-white {
        color: $white;
    }

    .offering-collections-highlight__link, .offering-collections-highlight__link-white {
        @include media-breakpoint-only(md) {
            &:nth-child(2) {
                margin-right: 0; // Remove margin right from last element in a row to get a little more width to the boxes.
            }
        }

        @include media-breakpoint-up(lg) {
            &:last-child {
                margin-right: 0; // Remove margin right from last element in a row to get a little more width to the boxes.
            }
        }

        &:hover, &:focus {
            color: $white;
            background-color: $primary;
            background-image: none !important;
            text-decoration: none;

            svg {
                margin-left: auto;
            }

            .offering-collections-highlight__link-text-container {
                background-image: none;
            }

            @media screen and (prefers-reduced-motion: reduce) {
                svg {
                    margin-left: inherit; // Keep arrow on the left of the box and do not put svg to the right side of the box upon hover when reduced motion is active.
                }
            }
        }
    }

    .offering-collections-highlight__link-background-image {
        // background image comes from style tag in _OfferingListHighlight.cshtml file.
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .offering-collections-highlight__link-text-container {
        margin: -$gap-medium;
        height: 100%;
        background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    .offering-collections-highlight__link-text {
        @include font-primary-bold();
        margin: $gap-medium;
        display: inline-block;
    }
}
