﻿// TODO - replace with BF.com styles!!! Directly taken from Finpro.Web solution.
$color-brand-black: #191919;
$font-size-base: 1.6rem;
$font-size-small: 1.4rem;
$font-size-site-locator: 1.8rem;

.regional-offering-block {
    .regional-offering-map#main-regional-offering-map {
        min-height: 550px;

        a:hover {
            text-decoration: none;
        }

        .clearfix:after {
            content: "";
            display: block;
            clear: both;
        }

        iframe {
            width: 100%;
            height: 800px;
            border: none;
        }

        #map, #map2 {
            width: 100%;
            height: 500px;
            position: relative;
            display: block;
            z-index: 2;
        }

        .custom-controls-wrapper {
            margin-left: 92%;
            margin-bottom: 10px;
        }

        .custom-zoom-out {
            text-align: center;
            width: 33px;
            height: 33px;
            background-image: url(../../icons/minus.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
        }

        .custom-zoom-in {
            text-align: center;
            width: 33px;
            height: 33px;
            background-image: url(../../icons/plus.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
            border: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            margin-bottom: 2px;
        }

        #filters {
            background-color: white;
            color: #000 !important;

            p, label {
                color: #000 !important;
            }
        }

        .blueBorderTop {
            border-top: 1px solid $blue;
        }

        .filterImg {
            width: 20px;
        }

        .closeFilter {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            width: 20px;
            height: 20px;
        }

        .map-list-buttons button, .maplink {
            background-color: #fff;
            color: $primary;
        }

        .button {
            border: 3px solid $primary;
            padding: 10px;
            cursor: pointer;
            width: 95px;
            text-align: center;
            color: $primary;
        }

        .button-active {
            background-color: $primary !important;
            color: #fff !important;
        }

        .accordion-button {
            background-color: white;
            color: #000;
            border: none;
            padding: 0;
            box-shadow: none;

            :focus {
                @include focus-styles();
            }
        }

        .accordion-body {
            border: none;
            color: #000
        }

        .accordion-item {
            border: none;
        }

        input[type='radio']:checked + .form-element__radio-label {
            @include font-primary-bold();
        }

        .filter-checkbox-row-investment-opportunities-list {
            padding: 0.5rem;
            padding-right: 0;
            padding-left: 20px;
            padding-bottom: 10px
        }

        .border-investment-list {
            border-bottom: 1px solid $primary;
        }

        .pointer-icon-list {
            width: 20px;
            height: 20px;
        }

        .fontSize14 {
            font-size: 14px;
        }

        #listContainer {
            display: none;
            max-height: 665px;
            overflow-y: auto;
        }

        #listContainer span {
            color: $primary;
        }

        #listContainer #list {
            list-style-type: none;
            width: 100%;
        }

        #listContainer #list a {
            font-size: 1rem;
            text-decoration: none;
            color: $primary;
        }

        .map-item__title {
            font-size: 0.7rem;
            text-align: left;

            @include media-breakpoint-up(sm) {
                font-size: 1rem;
            }
        }

        .map-item__link {
            font-size: 0.7rem;
            padding: 0;

            @include media-breakpoint-up(sm) {
                font-size: 1rem;
                padding: 7px 16px 6px;
            }
        }

        .noMargins {
            margin: 0;
        }

        .gm-style-iw #content {
            text-align: center;
        }

        .gm-style-iw h3 {
            text-transform: none;
        }
    }

    .accordion__toggler_arrow {
        &::after {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            background: url(../../img/form/arrow-down.svg) center center no-repeat;
            float: right;
        }

        &[aria-expanded="true"] {
            &::after {
                background-image: url(../../img/form/arrow-up.svg);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        #map {
            width: 92% !important;
        }
    }

    gmp-advanced-marker img {
        height: 49px;
    }
}
