/* Fading, sliding */
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-top {
    0% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-right {
    0% {
        transform: translateX(50px);
    }
    100% {
        transform: translateX(0);
    }
}

/* Reveal */
@keyframes reveal {
    0% {
        background-size: 0 0;
    }
    100% {
        background-size: 200% 200%;
    }
}

/* Placeholder reveal */
@keyframes remove-placeholder {
    0% {
        width: 100%;
        height: 100%;
        opacity: 1;
    }
    100% {
        width: 0;
        opacity: 0;
    }
}

/* Default states */
.reveal > * {
    background-size: 0% 0%;
    background: linear-gradient(135deg, rgba(255,255,255,1) 50%, transparent 55%);
    background-position: 0 0;
    background-repeat: no-repeat;
    color: #0000 !important;
    -webkit-background-clip: text;
    background-clip: text;
}
.fade {
    &-in, &-top, &-left, &-right {
        opacity: 0;
    }
}
.remove-placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: white;
}

/* Active animations */
.actived {
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &.fade {
        &-in, &-top, &-left, &-right {
            animation-duration: 800ms;
        }

        &-in {
            animation-name: fade;
        }

        &-top {
            animation-name: fade, slide-top;
        }

        &-left {
            animation-name: fade, slide-left;
        }

        &-right {
            animation-name: fade, slide-right;
        }
    }

    &.reveal > * {
        background-size: 200% 200%;
        animation-name: reveal;
        animation-duration: 800ms;
    }

    &.remove-placeholder::after {
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-duration: 800ms;
        animation-name: remove-placeholder;
    }
}

/* Ken-Burn effect */
@keyframes ken-burn {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

.ken-burn {
    animation-delay: 200ms;
    animation-name: ken-burn;
    animation-duration: 4s;
    transform-origin: center center;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

*:has(> .ken-burn) {
    overflow: hidden;
}

/* Delays */
@for $i from 1 through 30 {
    .delay-#{($i * 100)} {
        animation-delay: $i * 100ms;
    }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-name: none !important;
    }
    .reveal > * {
        background-size: 200% 200%;
    }
    .fade {
        &-in, &-top, &-left, &-right {
            opacity: 1;
        }
    }
    .remove-placeholder::after {
        width: 0;
        opacity: 0;
    }
}