.company-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    color: #000;
  }

  table {
    margin-top: 20px;
  }

  th {
    text-align: left;
    padding: 3px 11px 3px 0;
  }

  td {
    padding: 3px 0;
  }

  .btn-container {
    display: flex;
    gap: 10px;
  }

    p {
        color: #000;
    }

    table {
        margin-top: $gap-medium;
  }

  th {
    text-align: left;
    padding: 3px 11px 3px 0;
  }

  td {
    padding: 3px 0;
  }

  .btn-container {
    display: flex;

    gap: $gap-small;
  }

  p {
    color: #000;
  }

  table {
    margin-top: $gap-medium;
  }

  th {
    text-align: left;
    padding: 3px 11px 3px 0;
  }

  td {
    padding: 3px 0;
  }

  .btn-container {
    display: flex;
    gap: $gap-small;
  }

  .btn-container .btn {
    padding: 10px 5px;
  }
}

.company-cards-container {
  margin-top: $gap-large;
  margin-bottom: $gap-large;
}

.company-card__link {
  text-decoration: none;
  color: initial;
  padding: 5px;
  flex-direction: column;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin: 25px 0;

  &:focus,
  &:hover {
    text-decoration: none;
    background-color: $light-blue;
  }
}

.company-card__logo-wrapper {
  padding: 0 0 24px 0;
  text-align: center;
}

.company-card__logo, .company-card__logo_empty {
    height: 45px;
    display: inline-block;
}

.company-card__company-name {
  @include font-primary-bold();
  margin: 0 0 $gap-medium 0;
}

.company-card__stats {
  list-style-type: none;
}

.company-card__stats-item {
  margin-bottom: $gap-medium;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.company-card__icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  margin-right: 5px;

  &--globe {
    background: url(../../img/company-card/company-card-globe-icon-black.svg)
      center center no-repeat;
    top: 2px;
  }

  &--puzzle {
    background: url(../../img/company-card/company-card-puzzle-icon-black.svg)
      center center no-repeat;
    top: 2px;
  }

  &--tag {
    background: url(../../img/company-card/company-card-tag-icon-black.svg)
      center center no-repeat;
    top: 2px;
  }
}

.company-card__description {
  margin-bottom: $gap-medium;
}

.company-card__button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--loading {
    .company-card__spinner {
      display: inline-block;
    }
  }

  .company-card__spinner {
  width: 25px;
  height: 25px;
  background: url(../../img/form/spinner-icon.svg) center center no-repeat;
  background-size: contain;
  margin-right: 10px;
  display: none;
  }

  // These fontsizes are intentional the button does not fit the container. In figma the font size is text--t3 but it is too big.
  @include media-breakpoint-up(md) {
    .btn-container .btn {
      white-space: nowrap;
      font-size: 13px;
    }
  }

  @include media-breakpoint-up(lg) {
    .btn-container .btn {
      width: 100%;
    }

    .company-card table {
      margin-top: auto;
    }
  }
}
